import React from "react";
import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "../../icons/close-icon";
// theme
import theme from "../../theme";
import SecondaryButton from "../secondary-button";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(0),
  },
  title: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  closeIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    cursor: "pointer",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  description: {
    maxHeight: 370,
    overflowY: "auto",
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
      marginRight: 0,
      maxHeight: "80vh",
    },
  },
  backBtn: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      height: "59px",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100vw",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: 0,
      alignItems: "center",
      boxShadow: "0px -4px 11px rgba(0, 0, 0, 0.25)",
      backgroundColor: theme.palette.white,
    },
  },
}));

const DescriptionDialog = ({ name, description, open, onClose, fullScreen }) => {
  const classes = useStyles(theme);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      PaperProps={{
        style: {
          borderRadius: "10px",
          borderBox: "box-size",
        },
      }}
    >
      <Box className={classes.closeIcon} onClick={onClose}>
        <CloseIcon width="15px" height="15px" />
      </Box>
      <Box className={classes.container}>
        <Box mt={2} mb={1} pl={4} pr={4}>
          <Typography variant="h1" color="primary">
            {name}
          </Typography>
        </Box>
        <Box mt={2} mr={2} display="flex" flexDirection="row" justifyContent="flex-end" className={classes.description}>
          <Typography variant="body1">{description}</Typography>
        </Box>
        <Box mt={2} className={classes.backBtn}>
          <SecondaryButton onClick={onClose}>
            <Typography variant="button" color="primary">
              <Trans>Cerrar</Trans>
            </Typography>
          </SecondaryButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DescriptionDialog;
